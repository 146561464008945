<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular">
            <li>
              <span>Solutions</span>
            </li>
            <li>
              <span>Attribution</span>
            </li>
            <li class="ProximaNovaBold">Marketing Channel From</li>
          </ul>
        </div>
        <div class="container mb-5">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <div class="row">
                <div class="w-100" style="height: 64px">
                  <div class="p-4 row">
                    <div class="w-100">
                      <ol class="step-indicator">
                        <li class="stepactive">
                            <div class="step active">
                              <span>1</span>
                            </div>
                          <h5 class="font-medium">Platforms</h5>
                        </li>
                        <li class="stepactive">
                          <div class="step active">
                            <span>2</span>
                          </div>
                          <h5 class="font-medium">Data Source</h5>
                        </li>
                        <li class="complete stepactive">
                           <div
                            class="roundStep d-flex"
                            style="align-items: center"
                          >
                            <div class="step active">
                              <span>3</span>
                            </div>
                          </div>
                          <h5 class="font-medium">KPI</h5>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid mt-5">
          <div class="card w-100">
            <div class="card-body d-flex custom-body">
                <div> <img class="group-21" src="../../assets/group-21.png" /></div>
                <div><h1 class="your-KPI-market">Define KPI</h1>
                <div class="dropdownCover_wrapper">
                 <div class="dropdown_cover">
                   <span class="dropdownHead">KPI</span>
                   <select class="dropdown">
                      <option class="dropdownOption">1</option>
                      <option class="dropdownOption">2</option>
                      <option class="dropdownOption">3</option>
                   </select>
                 </div>
                 <div class="dropdown_cover">
                   <span class="dropdownHead">Market Type</span>
                   <select class="dropdown">
                      <option class="dropdownOption">1</option>
                      <option class="dropdownOption">2</option>
                      <option class="dropdownOption">3</option>
                   </select>
                 </div>
                </div>
                <button
                        color="primary isi"
                        class="btn-effect"
                        size="button--size-m"
                      >
                        Create Plan<i
                          class="fas fa-arrow-right ml-5 pl-4 d-flex"
                          style="align-items: center"
                        ></i>
                      </button>
                </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  name: "KpiDetails",
  data() {
    return {
      activeTab: "Digital Media",
      selectedConnectors: [],
      activeTabConnectors: [],
      tabs: [
        {
          name: "Digital Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "google", role: "DM Facebook" },
                { name: "Instagram", image: "google", role: "DM Instagram" },
                { name: "Twitter", image: "google", role: "DM Twitter" },
                { name: "LinkedIn", image: "google", role: "DM LinkedIn" },
                { name: "DV360", image: "google", role: "DM DV360" },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "google",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "google",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "google", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          name: "CRM",
          tabContent: [],
        },
        {
          name: "Sponsorship",
          tabContent: [],
        },
      ],
    };
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addPlatform(platformobj, title) {
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }
    },
  },
  created() {
    this.activeTabConnectors = this.digitalMediaConnectors;
    // this.username = sessionStorage.getItem("userName");
  },
};
</script>
<style scoped>
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}

.step-indicator {
  border-collapse: separate;
  display: table;
  margin-left: 0px;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  padding-left: 0;
  z-index: 0;
}

.step-indicator li {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.step-indicator .step {
  background-color: #e8edf5;
  border-radius: 50%;
  color: #8394b4;
  font-size: 24px;
  height: 57px;
  line-height: 52px;
  margin: 0 auto;
  position: relative;
  width: 57px;
  z-index: 1;
  border: 2px solid #8394b4;
  transform: translate(0.5px, -0.3px);
}

.roundStep {
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 24px;
  height: 64px;
  line-height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  border: 2px solid #050505;
}

.step-indicator li::after {
  background-color: #8394b4;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 100%;
  top: 28px;
}

.step-indicator li.stepactive::after {
  border: 1.5px solid #050505;
}

.step-indicator li.complete::after {
  left: -50%;
}

.step.active {
  border: 1.5px solid #050505;
  background-color: #050505 !important;
}

.step-indicator span {
  color: #8394b4;
}

.step-indicator h5 {
  color: #8394b4;
  font-family: ProximaNovaRegular;
  margin-top: 7px;
}

.step-indicator .stepactive span {
  color: #fff;
}

.step-indicator .stepactive h5 {
  color: #050505;
  font-family: ProximaNovaBold;
}

.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}

.action {
  color: #000;
}

.container {
  height: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-header {
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin: 24px 0 0 9px;
}

.step-indicator .stepactive::after {
  background: #050505;
}

.connector {
  background-color: #e9f0ff;
  height: 80px;
  width: 324px;
  cursor: pointer;
  font-family: ProximaNovaRegular;
  margin: 11px;
}

.connector.active {
  background-color: #c8f0d1;
  font-family: ProximaNovaBold;
}

.connector:hover {
  background-color: #c8f0d1;
  font-family: ProximaNovaBold;
}

.container-tab {
  max-width: 500px;
  padding-top: 80px;
}

.tab {
  cursor: pointer;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.card {
  display: block;
}

.button {
  padding: 0.75rem;
  height: 60px;
  float: right;
  cursor: pointer;
  background-color: #050505;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.group-21 {
  width: 301.9px;
  height: 300px;
  margin: 0 122.1px 0 0;
  object-fit: contain;
}

.your-KPI-market {
  margin: 0 172px 32px 0;
  font-family: ProximaNova;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #222a37;
}

.dropdown_cover {
    background-color: #eff5ff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    display: flex;
}

.dropdown {
  width: 264px;
  height: 21px;
  padding-left: 17px;
  border: none;
  border-left: revert;
  border-color: #c6ccd6;
  background-color: #eff5ff;
}
.dropdownHead {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  padding-right: 80px;
  padding-left: 17px;
}

.dropdownCover_wrapper {
    display: flex;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  float: right;
  margin-right: 25px;
  margin-top: 35px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}

.custom-body {
  padding-top: 157px;
  padding-left: 105px;
  padding-bottom: 157px;
  padding-right: 105px;
  justify-content: center;
}

.card {
    margin-top: 86px;
}
</style>